<template>
  <div class="centerTopContainer">
    <div class="topTitle">
      <span class="innerTitle">健身运动累计总人次</span>
    </div>
    <div class="topPlate">
      <light-plate :lightNum="totalNumber" />
    </div>
    <div class="topDesc">
      <span>今日人次：{{todayNumber}}</span>
      <span>本月人次：{{monthNumber}}</span>
      <span>较上月：{{compareNumber}}人
        <span
          :class="arrowClass"
          style="font-size: 18px; color: #07f3f5"
        ></span
      ></span>
    </div>
  </div>
</template>

<script>
import LightPlate from '@/components/lightPlate'
import service from "@/utils/request"
export default {
  components: {
    LightPlate,
  },
  data() {
    return {
      timing: null,
      totalNumber: [0,0,0,0,0,0,0,0],
      todayNumber: 0,
      monthNumber: 0,
      compareNumber: 0,
      arrowClass: ''
    }
  },
  mounted() {
    this.getData();
    this.timeFn();
  },
  beforeDestroy() {
    clearInterval(this.timing);
  },
  methods: {
    timeFn() {
      this.timing = setInterval(() => {
        this.getData();
      }, 5000);
    },
    getData() {
      const myThis = this;
      service.get('/synthesis/sportData')
          .then((response) => {
            if(response.code != 200) {
              console.log(response.message);
              return false;
            }
            const data = response.data;
            let total = data.total + '';
            if(total.length < 8) {
              const length = 8 - total.length;
              for (let i = 0; i < length; i++) {
                total = '0'+total;
              }
            }
            const compare = data.month_total - data.last_month_total;
            if(compare < 0)
              myThis.arrowClass = 'iconfont icon-xiangxia3';
            if(compare > 0)
              myThis.arrowClass = 'iconfont icon-shang';
            myThis.totalNumber = total.split("");
            myThis.todayNumber = data.today_total;
            myThis.monthNumber = data.month_total;
            myThis.compareNumber = Math.abs(compare);
          })
          .catch((response) => {
            console.log(response);
          })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/index.scss';
.centerTopContainer {
  width: 100%;
  text-align: center;
  .topTitle {
    margin-bottom: 20px;
    font-size: 20px;
    font-family: PingFang SC, PingFang SC-Bold;
    font-weight: 700;
    color: rgba(255, 255, 255, 0.8);
    text-shadow: 0px 2px 7px 0px #0d65cd;
    .innerTitle {
      &::before {
        content: '';
        margin-right: 5px;
        display: inline-block;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 10px solid #709efb;
      }
      &::after {
        content: '';
        margin-left: 5px;
        display: inline-block;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-right: 10px solid #709efb;
      }
    }
  }
  .topPlate {
    width: 73%;
    height: 69px;
    margin: 0 auto;
    margin-top: 13px;
  }
  .topDesc {
    width: 62.6%;
    margin: 0 auto;
    margin-top: 21px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    font-size: 16px;
    font-family: Source Han Sans CN, Source Han Sans CN-Bold;
    font-weight: 700;
    background-image: url('~@/assets/sportFacilities/top_bg.png');
    background-size: cover;
    padding: 6px 0;
  }
}
</style>
