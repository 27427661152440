<template>
  <div class="CenterBottomContainer">
    <ContentTop title="协会进入百姓健身房的开课服务人次排行" :type="3" />
    <div class="rank-list" style="margin-top: 21px">
      <rank-list :listHeight="208" :tableTitle="tableTitle" :list="areaData" />
    </div>
  </div>
</template>

<script>
import ContentTop from '@/components/contentTop'
import rankList from '@/components/rankList'
import service from "@/utils/request";
export default {
  components: {
    ContentTop,
    rankList,
  },
  data() {
    return {
      tableTitle: [
        {
          title: '排名',
          width: '150',
        },
        {
          title: '协会名称',
          val: 'first',
          width: '320',
        },
        {
          title: '开课服务人次',
          val: 'second',
          width: '310',
        },
      ],
      areaData: [],
    }
  },
  mounted() {
    this.getTrafficRankingData();
  },
  methods: {
    getTrafficRankingData() {
      // 百姓健身房人流量排行
      const myThis = this;
      service.get('/gym/trafficRanking')
          .then((response) => {
            if(response.code != 200) {
              console.log(response.message);
              return false;
            }
            const data = response.data;
            myThis.areaData = data.map((item) => {
              return {
                first: item.name,
                second: item.service_num,
              }
            });
          })
          .catch((response) => {
            console.log(response)
          })
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/index.scss';
.CenterBottomContainer {
  width: 100%;
  padding-bottom: 11px;
  //   padding: 0 68px 31px 68px;
}
</style>
