<template>
    <div class="centerTopContainer">
      <div class="topTitle">累计服务人次</div>
      <div class="topPlate">
        <light-plate :lightNum="serviceNum" />
      </div>
    </div>
  </template>

          <script>
  import LightPlate from "@/components/lightPlate";
  import service from "@/utils/request";
  export default {
    components: {
      LightPlate,
    },
    data() {
      return {
        serviceNum: [0,0,0,0,0,0,0,0],
        timing: null,
      };
    },
    mounted() {
      this.getCenterData();
      this.timeFn();
    },
    beforeDestroy() {
      clearInterval(this.timing);
    },
    methods: {
      timeFn() {
        this.timing = setInterval(() => {
          this.getCenterData();
        }, 5000);
      },
      getCenterData() {
        // 基础数据（屏幕中间）
        const myThis = this;
        service.get('/gym/basicInfo')
            .then((response) => {
              if(response.code != 200) {
                console.log(response.message);
                return false;
              }
              const data = response.data;
              let total = data.service_num + '';
              if(total.length < 8) {
                const length = 8 - total.length;
                for (let i = 0; i < length; i++) {
                  total = '0'+total;
                }
              }
              myThis.serviceNum = total.split("");
            })
            .catch((response) => {
              console.log(response)
            })
      },
    }
  };
  </script>

          <style lang="scss" scoped>
  @import "../../../assets/scss/index.scss";
  .centerTopContainer {
    width: 100%;
    text-align: center;
    .topTitle {
      font-size: 20px;
      font-family: PingFang SC, PingFang SC-Bold;
      font-weight: 700;
      color: rgba(255, 255, 255, 0.8);
      text-shadow: 0px 2px 7px 0px #0d65cd;
    }
    .topPlate {
      width: 73%;
      height: 69px;
      margin: 0 auto;
      margin-top: 13px;
    }
  }
  </style>
