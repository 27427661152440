<template>
  <div class="d-flex accumulateWrapper" :style="`padding-top:${paddingTop}px;`">
    <div class="imgBox" :style="`width:${width}px;height:${height}px;`">
      <img :src="leaguePic" />
    </div>
    <div class="accumulateBox">
      <div class="accumulateItem" v-if="hasSub" style="padding-bottom: 10px">
        <div
          :style="`line-height:1.3;margin-left:${labelMargin}px;font-size:${labelStyle?.fontSize}px;font-weight:${labelStyle?.fontWeight};width:${labelWidth}px;`"
        >
          {{ subLabel }}：
        </div>
        <div
          class="count"
          :style="`font-size:${countStyle?.fontSize}px;font-weight:${countStyle?.fontWeight}px;margin-left:${countMargin}px;`"
        >
          <countTo :startVal="0" :endVal="subCount" :duration="2000"></countTo>
        </div>
        <div
          class="unit"
          :style="`margin-left:${unitMargin}px;font-size:${unitStyle?.fontSize}px;font-weight:${unitStyle?.fontWeight};`"
        >
          {{ subUnit }}
        </div>
      </div>
      <div class="accumulateItem">
        <div
          :style="`line-height:1.3;margin-left:${labelMargin}px;font-size:${labelStyle?.fontSize}px;font-weight:${labelStyle?.fontWeight};width:${labelWidth}px;`"
        >
          {{ label }}：
        </div>
        <div
          class="count"
          :style="`font-size:${countStyle?.fontSize}px;font-weight:${countStyle?.fontWeight}px;margin-left:${countMargin}px;`"
        >
          <countTo :startVal="0" :endVal="count" :duration="2000"></countTo>
        </div>
        <div
          class="unit"
          :style="`margin-left:${unitMargin}px;font-size:${unitStyle?.fontSize}px;font-weight:${unitStyle?.fontWeight};`"
        >
          {{ unit }}
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import countTo from "vue-count-to";
import league_icon from "@/assets/sportFacilities/league_icon.png";
import gym_icon from "@/assets/sportFacilities/gym_icon.png";
import person_icon from "@/assets/sportFacilities/person_icon.png";
import guide_icon from "@/assets/sportFacilities/guide_icon.png";

export default {
  props: {
    iconName: { type: String, default: "league_icon" },
    paddingTop: { type: Number, default: 0 },
    width: { type: Number, default: 66 },
    height: { type: Number, default: 66 },
    label: { type: String, default: "" },
    hasSub: { type: Boolean, default: false },
    subLabel: { type: String, default: "" },
    labelMargin: { type: Number, default: 18 },
    labelWidth: { type: Number },
    labelStyle: {
      type: Object,
      default: () => {
        return {
          fontSize: 20,
          fontWeight: 700,
        };
      },
    },
    count: { type: Number },
    subCount: { type: Number },
    countMargin: { type: Number, default: 0 },
    countStyle: {
      type: Object,
      default: () => {
        return {
          fontSize: 32,
          fontWeight: 800,
        };
      },
    },
    unit: { type: String, default: "" },
    subUnit: { type: String, default: "" },
    unitMargin: { type: Number, default: 6 },
    unitStyle: {
      type: Object,
      default: () => {
        return {
          fontSize: 20,
          fontWeight: 700,
        };
      },
    },
  },
  components: { countTo },
  data() {
    return {
      leaguePic: league_icon,
    };
  },
  created() {
    this.leaguePic =
      this.iconName === "league_icon"
        ? league_icon
        : this.iconName === "person_icon"
        ? person_icon
        : this.iconName === "guide_icon"
        ? guide_icon
        : gym_icon;
    console.log(this.labelWidth);
  },
  methods: {},
};
</script>
  
  <style lang="scss" scoped>
.accumulateWrapper {
  align-items: center;
  .imgBox {
    img {
      width: 100%;
      height: 100%;
    }
  }

  .accumulateBox {
    .accumulateItem {
      display: flex;
      align-items: center;
      .count,
      .unit {
        font-family: PingFang SC, PingFang SC-Heavy;
      }

      .unit {
        position: relative;
        top: 3px;
      }
    }
  }
}
</style>