<template>
  <div id="leftLeague">
    <div class="leftLeagueContainer">
      <ContentTop title="体育社团情况" :type="1" />
      <div class="item-chart-top pos-relative">
        <!-- <div class="radioStyle">
          <Radio :radioProps="rankRadioProps" class="d-flex jc-end" />
        </div>  -->
        <div class="titltWrapper">
          <div class="titleBox">
            <div class="imgBox">
              <img src="@/assets/sportFacilities/league_icon.png" alt="" />
            </div>
            <div class="desBox" style="margin-left: 12px">
              <div class="desTop">社团总数量：</div>
              <div class="desBottom">{{topAllData.count}}<span>个</span></div>
            </div>
            <div class="desBox" style="margin-left: 18px">
              <div class="desTop">社团总人数：</div>
              <div class="desBottom">{{topAllData.subCount}}<span>人</span></div>
            </div>
          </div>
        </div>
        <div class="d-flex jc-between leftBox" style="margin-top: 16px">
          <!-- <LeagueItem :dataSoure="topLeftData" />
          <LeagueItem :dataSoure="topRightData" /> -->
          <div class="cityItem d-flex">
            <div class="leftItemBox">市级社团</div>
            <div class="rightItemBox">
              <div>
                <span>数量</span>
                <span style="margin-left: 8px">{{topLeftData.count}}个</span>
              </div>
              <div>
                <span>人数</span>
                <span style="margin-left: 8px">{{topLeftData.subCount}}人</span>
              </div>
            </div>
          </div>
          <div class="xianItem d-flex">
            <div class="leftItemBox">县级社团</div>
            <div class="rightItemBox">
              <div>
                <span>数量</span>
                <span style="margin-left: 8px">{{topRightData.count}}个</span>
              </div>
              <div>
                <span>人数</span>
                <span style="margin-left: 8px">{{topRightData.subCount}}人</span>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex jc-between" style="margin-top: 10px">
          <div class="leagueItem" style="margin-top: 12px">
            <div class="itemBox" v-for="(item, index) in leftData" :key="index">
              <LeagueItem :dataSoure="leftData[index]" />
            </div>
          </div>

          <div class="leagueCenter">
            <!-- width="166px" -->
            <Echart
              :options="options"
              id="bottomLeftChart2"
              height="160px"
              width="100%"
            ></Echart>
          </div>
          <div class="leagueItem" style="margin-top: 12px">
            <div
              class="itemBox"
              v-for="(item, index) in rightData"
              :key="index"
            >
              <LeagueItem :color="colorRight" :dataSoure="rightData[index]" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import echarts from 'echarts'
import Echart from '@/common/echart'
import ContentTop from '@/components/contentTop'
import AccumulateTip from '@/components/accumulateTip'
import Radio from '@/components/Radio'
import LeagueItem from '@/components/leagueItem'
import service from "@/utils/request"
import {numberToMoney} from "@/utils";

export default {
  components: {
    Echart,
    ContentTop,
    AccumulateTip,
    Radio,
    LeagueItem,
  },
  data() {
    return {
      timing: null,
      colorRight: '#6DC5F2',
      options: {
        color: ['#6dc5f2', '#ed73e8'],
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)',
        },
        grid: {
          // 让图表占满容器
          top: '0px',
          left: '0px',
          right: '0px',
          bottom: '0px',
        },
        calculable: true,
        series: [
          {
            name: '来源',
            type: 'pie',
            selectedMode: 'single',
            raduis: [0, '100%'],
            label: {
              normal: {
                position: 'inner',
              },
            },
            labelLine: {
              normal: {
                show: false,
              },
            },
            data: [],
          },
        ],
      },
      rankRadioProps: {
        name: 'rankRadio',
        options: [
          { value: 'week', label: '等级' },
          { value: 'ji', label: '星级' },
        ],
      },
      topAllData: {
        count: 0,
        subCount: 0,
      },
      topLeftData: {
        label: '市级社团个数',
        count: 0,
        subLabel: '市级社团人数',
        subCount: 0,
      },
      topRightData: {
        label: '县(市，区)级社团个数',
        count: 0,
        subLabel: '县(市，区)级社团人数',
        subCount: 0,
      },
      leftData: [
        {
          label: '5A级个数',
          count: 0,
        },
        {
          label: '4A级个数',
          count: 0,
        },
        {
          label: '3A级个数',
          count: 0,
        },
      ],
      rightData: [
        {
          label: '5A级个数',
          count: 0,
        },
        {
          label: '4A级个数',
          count: 0,
        },
        {
          label: '3A级个数',
          count: 0,
        },
      ],
    }
  },
  mounted() {
    // 获取数据
    this.getData();
    this.timeFn();
  },
  beforeDestroy() {
    clearInterval(this.timing);
  },
  methods: {
    timeFn() {
      this.timing = setInterval(() => {
        this.getData();
      }, 5000);
    },
    getData() {
      const myThis = this;
      service.get('/synthesis/businessData')
          .then(function (response) {
            if(response.code != 200) {
              console.log(response.message);
              return false;
            }
            const data = response.data;
            myThis.topAllData = {
              count: numberToMoney(data.business_total),
              subCount: numberToMoney(data.business_member)
            };
            myThis.topLeftData.count = numberToMoney(data.city_data.business_total);
            myThis.topLeftData.subCount = numberToMoney(data.city_data.business_member);
            myThis.topRightData.count = numberToMoney(data.area_data.business_total);
            myThis.topRightData.subCount = numberToMoney(data.area_data.business_member);
            myThis.leftData = [
              {
                label: '5A级个数',
                count: data.city_data['5A'],
              },
              {
                label: '4A级个数',
                count: data.city_data['4A'],
              },
              {
                label: '3A级个数',
                count: data.city_data['3A'],
              }
            ];
            myThis.rightData = [
              {
                label: '5A级个数',
                count: data.area_data['5A'],
              },
              {
                label: '4A级个数',
                count: data.area_data['4A'],
              },
              {
                label: '3A级个数',
                count: data.area_data['3A'],
              }
            ];
            myThis.options.series[0].data = [
              { value: data.area_data.business_total, name: '县级' },
              { value: data.city_data.business_total, name: '市级' },
            ];
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/index.scss';
#leftLeague {
  .leftLeagueContainer {
    // height: 318px;
    width: 100%;
    // padding: 20px 30px;
    margin-top: 10px;
    .titltWrapper {
      margin-top: 14px;
      .titleBox {
        display: flex;
        align-items: center;
        .imgBox {
          width: 66px;
          height: 66px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .desBox {
          .desTop {
            font-size: 20px;
            font-weight: 700;
          }
          .desBottom {
            font-size: 26px;
            font-family: PingFang SC, PingFang SC-Heavy;
            font-weight: 800;
            padding-top: 10px;
            span {
              font-size: 20px;
              font-weight: 700;
            }
          }
        }
      }
    }

    .leftBox {
      height: 66px;
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Bold;
      font-weight: 700;
      .cityItem {
        width: 45.5%;
        background: url('~@/assets/sportFacilities/city_icon.png') no-repeat;
        background-size: 100% 100%;
      }
      .xianItem {
        width: 45.5%;
        background: url('~@/assets/sportFacilities/xian_icon.png') no-repeat;
        background-size: 100% 100%;
      }
      .leftItemBox {
        width: 30px;
        padding-top: 14px;
        margin-left: 20px;
      }

      .rightItemBox {
        font-weight: 500;
        padding-top: 16px;
        margin-left: 48px;
      }
    }
    .radioStyle {
      position: absolute;
      right: 0;
    }
    .leagueCenter {
      flex: 1;
      //   background: red;
    }
    .leagueItem {
      .itemBox {
        margin-top: 10px;
        // text-align: center;
        &:first-child {
          margin-top: 5px;
        }
      }
    }
  }
}
</style>
