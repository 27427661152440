<template>
  <div id="rightGuide">
    <div class="rightGuideContainer">
      <ContentTop title="指导员情况" :type="1" />
      <div>
        <AccumulateTip
          :paddingTop="13"
          :width="66"
          label="指导员总人数"
          :count="totalNumber"
          unit="人"
          iconName="guide_icon"
        />
        <!-- <Echart
          :options="options"
          id="bottomLeftChart1"
          height="200px"
          width="100%"
        ></Echart> -->
        <div style="margin-top: 8px">
          <Sliders
            @sliderChange="handleChange"
            height="160px"
            :options="useData"
          />
        </div>
      </div>
    </div>
  </div>
</template>

    <script>
import echarts from "echarts";
import Echart from "@/common/echart";
import ContentTop from "@/components/contentTop";
import AccumulateTip from "@/components/accumulateTip";
import Sliders from "@/components/Sliders";
import service from "@/utils/request"

export default {
  components: {
    Echart,
    ContentTop,
    AccumulateTip,
    Sliders
  },
  data() {
    return {
      timing: null,
      totalNumber: 0,
      useData: [],
      options: {
        tooltip: {
          trigger: "axis",
          backgroundColor: "#303030",
          borderColor: "#2DCDFF", // 修改字体颜色
          borderWidth: 1,
          axisPointer: {
            type: "shadow",
          },
          formatter: function (params) {
            console.log(params);
            let res = "";
            res = `
                          <div style="padding:8px 12px;">
                            <div>指导员情况：</div>
                            <div style="font-size: 12px;padding-top: 6px">总数：${params[0]?.data}人</div>
                            <div style="font-size: 12px;">较去年：12%  <span class="iconfont icon-shang" style="font-size: 16px; color: #07f3f5;" /></div>
                          </div>`;
            return res;
          },
        },
        grid: {
          top: "18%",
          left: "10%",
          right: "2%",
          bottom: "26%",
        },
        xAxis: {
          data: [
            "瓯海区",
            "龙湾区",
            "鹿城区",
            "永嘉县",
            "乐清市",
            "瑞安市",
            "文成县",
            "平阳县",
            "龙港市",
            "洞头区",
            "泰顺县",
            "苍南县",
          ],
          axisLabel: {
            interval: 0,
            // 垂直显示
            formatter: function (value) {
              return value.split("").join("\n");
            },
          },
        },
        yAxis: [
          {
            name: "场",
            type: "value",
          },
        ],
        series: [
          {
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  1,
                  0,
                  0,
                  [
                    {
                      offset: 0,
                      color: "rgba(8,253,254,0)",
                    },
                    {
                      offset: 1,
                      color: "rgba(8,253,254,1)",
                    },
                  ],
                  false
                ),
              },
            },
            data: [700, 400, 450, 610, 340, 110, 610, 500, 200, 311, 720, 360],
            type: "bar",
            barWidth: 10,
          },
        ],
      },
    };
  },
  mounted() {
    this.getData();
    this.timeFn();
  },
  beforeDestroy() {
    clearInterval(this.timing);
  },
  methods: {
    timeFn() {
      this.timing = setInterval(() => {
        this.getData();
      }, 5000);
    },
    getData() {
      const myThis = this;
      service.get('/synthesis/instructorRanking')
          .then((response) => {
            if(response.code != 200) {
              console.log(response.message);
              return false;
            }
            const data = response.data;
            myThis.totalNumber = parseInt(data.person_num);
            myThis.useData = data.list.map((item) => {
              return { value: item.num, label: item.name, rightText: item.num+'人' }
            })
          })
          .catch((response) => {
            console.log(response)
          })
    },
    handleChange(){},
  }
};
</script>

    <style lang="scss" scoped>
@import "../../../assets/scss/index.scss";
#rightGuide {
  margin-top: 50px;
  .rightGuideContainer {
    width: 444px;
  }
}
</style>
