<template>
  <div id="RightVideo">
    <div class="RightVideoContainer">
      <ContentTop :title="`体育场地总面积：${totalArea}万平方米`" :type="1" />
      <div class="titltWrapper">
        <div class="titleBox">
          <div class="imgBox">
            <img src="@/assets/sportFacilities/tip_icon.png" alt="" />
          </div>
          <div class="desBox" style="margin-left: 12px">
            <div class="desTop">体育场地设施数量:</div>
            <div class="desBottom">{{equipmentNumber}}<span style="margin-left: 6px;">个</span></div>
          </div>
          <div class="desBox" style="margin-left: 18px">
            <div class="desTop">人均体育场地面积:</div>
            <div class="desBottom">{{landNumber}}<span style="margin-left: 6px;">平方米</span></div>
          </div>
        </div>
      </div>
      <div class="item-chart-top">
        <Echart
          :options="options"
          id="RightVideo"
          height="170px"
          width="100%"
        ></Echart>
      </div>
    </div>
  </div>
</template>

      <script>
import echarts from "echarts";
import Echart from "@/common/echart";
import service from "@/utils/request"

import ContentTop from "@/components/contentTop";
import {numberToMoney} from "@/utils";
var data = {
  man: [280, 300, 320, 380],
  woman: [240, 258, 280, 320],
  yName: ["瓯海区", "鹿城区", "龙湾区", "乐清市"],
  legendData: ["设施数量", "总面积"],
};
export default {
  components: {
    ContentTop,
    Echart,
  },
  data() {
    return {
      timing: null,
      totalArea: 0,
      equipmentNumber: 0,
      landNumber: 0,
      listData: [],
      options: {
        tooltip: {
          trigger: "axis",
          backgroundColor: "#303030",
          borderColor: "#2DCDFF", // 修改字体颜色
          borderWidth: 1,
          axisPointer: {
            type: "shadow",
          },
          formatter: function (params) {
            let res = "";
            let str = "";
            params.forEach((item, index) => {
              str += ` <div style="font-size: 12px;padding-top: 12px; display: flex; align-items: center; justify-content: space-around;">
                <div>
            <div>${index === 0 ? "场地设施数量" : "人均体育场地面积"}：</div>
            <div >${item?.data}${index === 0 ? "个" : "平方米"}</div>
          </div>
          <div style="margin-left: 10px;">
            <div>排名</div>
            <div></div>
          </div>
                              </div>`;
            });
            res = `
                            <div style="padding:8px 12px;">
                              <div>体育场地设施数量及总面积：</div>
                              ${str}
                            </div>`;
            return res;
          },
        },
        grid: {
          top: "18%",
          left: "10%",
          right: "2%",
          bottom: "26%",
        },
        legend: {
          data: ["场地设施数量", "人均体育场地面积"],
          align: "right",
          right: 10,
          textStyle: {
            color: "#fff",
          },
          itemWidth: 10,
          itemHeight: 10,
          itemGap: 15,
        },
        xAxis: {
          data: [
            "瓯海区",
            "龙湾区",
            "鹿城区",
            "永嘉县",
            "乐清市",
            "瑞安市",
            "文成县",
            "平阳县",
            "龙港市",
            "洞头区",
            "泰顺县",
            "苍南县",
          ],
          axisLabel: {
            interval: 0,
            // 垂直显示
            formatter: function (value) {
              return value.split("").join("\n");
            },
          },
        },
        yAxis: [
          {
            name: "个/平方米",
            type: "value",
          },
        ],
        series: [
          {
            name: "场地设施数量",
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  1,
                  0,
                  0,
                  [
                    {
                      offset: 0,
                      color: "rgba(8,253,254,0)",
                    },
                    {
                      offset: 1,
                      color: "rgba(8,253,254,1)",
                    },
                  ],
                  false
                ),
              },
            },
            data: [],
            type: "bar",
            barWidth: 8,
          },
          {
            name: "人均体育场地面积",
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  1,
                  0,
                  0,
                  [
                    {
                      offset: 0,
                      color: "#FFA1A0",
                    },
                    {
                      offset: 1,
                      color: "#FF6766",
                    },
                  ],
                  false
                ),
              },
            },
            data: [],
            type: "bar",
            barWidth: 6,
          },
        ],
      },
    };
  },
  mounted() {
    this.getData();
    this.timeFn();
  },
  beforeDestroy() {
    clearInterval(this.timing);
  },
  methods: {
    timeFn() {
      this.timing = setInterval(() => {
        this.getData();
      }, 5000);
    },
    getData() {
      const myThis = this;
      service.get('/synthesis/siteArea')
          .then((response) => {
            if(response.code != 200) {
              console.log(response.message);
              return false;
            }
            const data = response.data;
            myThis.totalArea = data.site_area / 10000;
            myThis.equipmentNumber = numberToMoney(data.site_equipment);
            myThis.landNumber = data.avg_land;
            myThis.options.xAxis.data = data.list.map((item) => {
              return item.name
            })
            myThis.options.series[0].data = data.list.map((item) => {
              return item.site_equipment
            })
            myThis.options.series[1].data = data.list.map((item) => {
              return item.avg_land
            })
            myThis.options.tooltip.formatter = (params) => {
                let res = "";
                let str = "";
                params.forEach((item, index) => {
                  str += ` <div style="font-size: 12px;padding-top: 12px; display: flex; align-items: center; justify-content: space-around;">
                  <div>
              <div>${index === 0 ? "场地设施数量" : "人均体育场地面积"}：</div>
              <div >${item?.data}${index === 0 ? "个" : "平方米"}</div>
            </div>
            <div style="margin-left: 10px;">
              <div>排名</div>
              <div>${index === 0 ? data.list[item.dataIndex].site_equipment_ranking : data.list[item.dataIndex].avg_land_ranking}</div>
            </div>
                                </div>`;
                });
                res = `
                              <div style="padding:8px 12px;">
                                <div>体育场地设施数量及总面积：</div>
                                ${str}
                              </div>`;
                return res;
              }
          })
          .catch((response) => {
            console.log(response)
          })
    },
  },
};
</script>

      <style lang="scss" scoped>
@import "../../../assets/scss/index.scss";
#RightVideo {
  .RightVideoContainer {
    height: 269px;
    width: 100%;
    // padding: 20px 30px;
    .titltWrapper {
      margin-top: 14px;
      .titleBox {
        display: flex;
      align-items: center;
        .imgBox {
        width: 66px;
        height: 66px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .desBox {
        .desTop {
          font-size: 20px;
          font-weight: 700;
        }
        .desBottom {
          margin-top: 2px;
          font-size: 26px;
          font-family: PingFang SC, PingFang SC-Heavy;
          font-weight: 800;
          padding-top: 8px;
          span {
            font-size: 20px;
            font-weight: 700;
          }
        }
      }
      }

    }
  }
}
</style>
