<template>
  <div id="rightGuide">
    <div class="rightGuideContainer">
      <ContentTop title="赛事活动情况" :type="1" />
      <div>
        <div class="matchTitle">
          <span class="bgSpan">赛事级别</span>
          <div class="bgTitle"></div>
        </div>
        <div class="matchContent">
          <div
            class="d-flex contentItem"
            v-for="(item, index) in activityData"
            :key="index"
          >
            <Parall
              :rank="index + 1"
              :color="item.color"
              :bgColor="item.bgColor"
            />
            <div class="itemRight">
              <div>{{ item.label }}</div>
              <div class="rightBottom">
                <span>{{ item.value }}</span
                ><span class="labelDes">场</span>
              </div>
            </div>
          </div>
        </div>
        <div class="matchTitle">
          <span class="bgSpan">赛事情况</span>
          <div class="bgTitle"></div>
        </div>
        <Echart
          :options="options"
          id="bottomLeftChart1"
          height="200px"
          width="100%"
        ></Echart>
      </div>
    </div>
  </div>
</template>

      <script>
import echarts from "echarts";
import Echart from "@/common/echart";
import ContentTop from "@/components/contentTop";
import Parall from "./parall";
import service from "@/utils/request"
export default {
  components: {
    Echart,
    ContentTop,
    Parall,
  },
  data() {
    return {
      timing: null,
      options: {
        tooltip: {
          trigger: "axis",
          backgroundColor: "#303030",
          borderColor: "#2DCDFF", // 修改字体颜色
          borderWidth: 1,
          axisPointer: {
            type: "shadow",
          },
          formatter: function (params) {
            let res = "";
            let str = ''
            params.forEach((item, index) => {
              str += ` <div style="font-size: 12px;padding-top: 6px">
                                <span>${item?.seriesName}：${item?.data}次</span>
                              </div>`
            })
            res = `
                            <div style="padding:8px 12px;">
                              <div>赛事活动情况：</div>
                              ${str}
                            </div>`;
            return res;
          },
        },
        grid: {
          top: "18%",
          left: "8%",
          right: "0%",
          bottom: "20%",
        },
        legend: {
          data: ["省级", "市级", "县级"],
          align: "right",
          right: 10,
          textStyle: {
            color: "#fff",
          },
          itemWidth: 10,
          itemHeight: 10,
          itemGap: 15,
        },
        xAxis: {
          data: [
            "1月",
            "2月",
            "3月",
            "4月",
            "5月",
            "6月",
            "7月",
            "8月",
            "9月",
            "10月",
            "11月",
            "12月",
          ],
        },
        yAxis: [
          {
            name: "次",
            type: "value",
          },
        ],
        series: [
          {
            name: "省级",
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  1,
                  0,
                  0,
                  [
                    {
                      offset: 0,
                      color: "rgba(8,253,254,0)",
                    },
                    {
                      offset: 1,
                      color: "rgba(8,253,254,1)",
                    },
                  ],
                  false
                ),
              },
            },
            data: [700, 400, 450, 610, 340, 110, 610, 500, 200, 311, 720, 360],
            type: "bar",
            barWidth: 6,
          },
          {
            name: "市级",
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  1,
                  0,
                  0,
                  [
                    {
                      offset: 0,
                      color: "#FFA1A0",
                    },
                    {
                      offset: 1,
                      color: "#FF6766",
                    },
                  ],
                  false
                ),
              },
            },
            data: [600, 300, 450, 510, 440, 210, 610, 300, 500, 111, 620, 760],
            type: "bar",
            barWidth: 6,
          },
          {
            name: "县级",
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  1,
                  0,
                  0,
                  [
                    {
                      offset: 0,
                      color: "#D69E2D",
                    },
                    {
                      offset: 1,
                      color: "#D69E2D",
                    },
                  ],
                  false
                ),
              },
            },
            data: [300, 700, 650, 310, 140, 210, 710, 600, 400, 311, 610, 160],
            type: "bar",
            barWidth: 6,
          },
        ],
      },
      activityData: [],
    };
  },
  mounted() {
    this.getData();
    this.timeFn();
  },
  beforeDestroy() {
    clearInterval(this.timing);
  },
  methods: {
    timeFn() {
      this.timing = setInterval(() => {
        this.getData();
      }, 5000);
    },
    getData() {
      const myThis = this;
      service.get('/synthesis/actData')
          .then((response) => {
            if(response.code != 200) {
              console.log(response.message);
              return false;
            }
            const data = response.data;
            const list = [];
            const tmpChartData = [];
            const color = [
              ["rgba(8,253,254,0)","rgba(8,253,254,1)"],
              ["#FFA1A0","#FF6766"],
              ["#D69E2D","#fdc45a"],
              ["#66bd0b","#8eff68"],
              ["#0b79bd","#19bdfa"],
            ];
            for (let key in data.total_data) {
              const tmp = {
                label: data.total_data[key].name,
                value: data.total_data[key].num,
                color: "#88BFFF",
                bgColor: "#056FEB",
              };
              if(list.length == 0) {
                tmp.color = "#FECD6E";
                tmp.bgColor = "#604e30";
              }
              list.push(tmp)
            }
            for (let i = 0; i < data.list.length; i++) {
              let num = 0;
              for (let key in data.list[i].data) {
                if(tmpChartData[num]){
                  tmpChartData[num].num.push((data.list[i].data)[key].num);
                }
                else
                  tmpChartData.push({
                    name: (data.list[i].data)[key].name,
                    num: [(data.list[i].data)[key].num]
                  })
                num++;
              }
            }
            myThis.activityData = list;
            myThis.options.xAxis.data = data.list.map((item) => {
              return item.month+'月'
            });
            myThis.options.legend.data = tmpChartData.map((item) => item.name)
            myThis.options.series = tmpChartData.map((item,index) => {
              return {
                name: item.name,
                itemStyle: {
                  normal: {
                    color: new echarts.graphic.LinearGradient(
                        0,
                        1,
                        0,
                        0,
                        [
                          {
                            offset: 0,
                            color: color[index][0],
                          },
                          {
                            offset: 1,
                            color: color[index][1],
                          },
                        ],
                        false
                    ),
                  },
                },
                data: item.num,
                type: "bar",
                barWidth: 6,
              }
            })
          })
          .catch((response) => {
            console.log(response)
          })
    },
  }
};
</script>

      <style lang="scss" scoped>
@import "../../../assets/scss/index.scss";
#rightGuide {
  margin-top: 13px;
  margin-bottom: 33px;
  .rightGuideContainer {
    width: 444px;

    .matchTitle {
      margin-top: 13px;
      margin-bottom: 16px;
      position: relative;
      .bgSpan {
        position: absolute;
        bottom: -8px;
        z-index: 100;
      }
      .bgTitle {
        position: relative;
        top: 10px;
        width: 86px;
        height: 9px;
        background: linear-gradient(-45deg, transparent 9px, #052457 0) right;
        // border: 1px solid;
        // border-image: linear-gradient(352deg, #86d6ff 0%, rgba(0, 0, 0, 0) 100%)
        //   1 1;
      }
      // background: red;
      span {
        font-size: 14px;
        font-weight: 700;
      }
    }

    .matchContent {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 9px;
      .contentItem {
        align-items: center;
        .itemRight {
          margin-left: 14px;
          font-size: 14px;
          font-weight: 700;
          .rightBottom {
            margin-top: 3px;
            .labelDes {
              font-size: 12px;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}
</style>
