<template>
	<div class="content">
		<div class="item" v-for="item in totalTimes" :key="item" :class="(item * comVal) < val?'active':''"></div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				totalTimes:50,
				comVal:2,
			};
		},
		props: {
			val: {
				type: Number,
				default: 59,
			},
			size:{
				type: String,
				default: 'small',
			}
		},
		watch:{
			size:{
				handler(val){
					if(val == 'small'){
						this.totalTimes=50;
						this.comVal=2;
					}else{
						this.totalTimes=100;
						this.comVal=1;
					}
				},
				immediate: true,
				deep: true
			},
		},
		methods:{
			
		}
	};
</script>

<style lang="scss" scoped>
	.content{
		width:100%;
		height:6px;
		background: rgba(42,157,255,0.20);
		border-radius: 3px;
		display: flex;
		align-items: center;
		.item{
			width:2%;
			height:6px;
			border-radius:2px;
		}
		.active{
			background-color: #2a9dff;
		}
	}
</style>
