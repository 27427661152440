<template>
  <div class="CenterDataContainer">
    <ContentTop
      title="相关数据排行"
      :topRadioProps="activeRankTopRadioProps"
      :type="3"
    />
    <div class="rank-list" style="margin-top: 21px">
      <rank-list view-type="other" :listHeight="345" />
    </div>
  </div>
</template>
              
              <script>
import ContentTop from "@/components/contentTop";
import rankList from "@/components/rankList";
export default {
  components: {
    ContentTop,
    rankList,
  },
  data() {
    return {
      activeRankTopRadioProps: {
        name: "activeRank",
        width: 60,
        options: [
          { value: "qu", label: "区" },
          { value: "xian", label: "县" },
        ],
      },
      tableTitle: [
        {
          title: "星级等级",
          width: "150",
        },
        {
          title: "数量",
          val: "first",
          width: "150",
        },
        {
          title: "覆盖率",
          val: "second",
          width: "150",
        },
      ],
    };
  },
};
</script>
              
              <style lang="scss" scoped>
@import "../../../assets/scss/index.scss";
.CenterDataContainer {
}
</style>
              