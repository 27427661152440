<template>
  <div class="LeftDisplayContainer">
    <ContentTop title="四类数据展示" />
    <div class="item-chart-top">
      <Echart
        :options="options"
        id="LeftDisplayChart"
        height="190px"
        width="100%"
      ></Echart>
    </div>
  </div>
</template>

              <script>
import echarts from "echarts";
import Echart from "@/common/echart";
import ContentTop from "@/components/contentTop";
import rankList from "@/components/rankList";
import service from "@/utils/request";
export default {
  components: {
    ContentTop,
    rankList,
    Echart,
  },
  data() {
    return {
      activeRankTopRadioProps: {
        name: "activeRank",
        width: 60,
        options: [
          { value: "qu", label: "区" },
          { value: "xian", label: "县" },
        ],
      },
      options: {
        backgroundColor: "transparent",
        grid: {
          top: 20,
          bottom: 0,
          left: 20,
          right: 0,
        },
        tooltip: {},
        xAxis: {
          show: false,
          type: "value",
        },
        yAxis: [
          {
            type: "category",
            show: true,
            inverse: true,
            axisLabel: {
              show: false,
              textStyle: {
                color: "rgba(255,255,255,.6)",
              },
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            data: ["一类总数", "二类总数", "城市总数", "农村总数"],
          },
          {
            type: "category",
            inverse: true,
            axisTick: "none",
            axisLine: "none",
            show: true,
            axisLabel: {
              textStyle: {
                color: "#ffffff",
                fontSize: "14",
              },
              align: "right",
              padding: [0, 30, 0, 0],
              verticalAlign: "bottom",
              lineHeight: 36,
              // 空闲车位、占比
              formatter: function (value, index) {
                return `{a|${value}}`;
              },
              rich: {
                a: {
                  fontSize: 18,
                  fontFamily: "OPPOSans",
                  padding: [0, 3, 0, 0],
                },
              },
            },
            data: ["12640", "8986", "6762", "5521"],
          },
        ],
        series: [
          // 条形图
          {
            name: "数量",
            type: "bar",
            zlevel: 1,
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  {
                    offset: 0,
                    color: "#273d66",
                  },
                  {
                    offset: 1,
                    color: "#0570eb",
                  },
                ]),
              },
            },
            barWidth: 4,
            data: ["12640", "8986", "6762", "5521"],
            label: {
              normal: {
                color: "#fff",
                show: true,
                position: [0, "-23px"],
                textStyle: {
                  fontSize: "14",
                  fontFamily: "customFont",
                  color: "#FFFFFF",
                },
                // 序号、停车场名称
                formatter: function (value) {
                  return `{b|${value.name}}`;
                },
                rich: {
                  b: {
                    fontSize: "14",
                    fontFamily: "customFont",
                    color: "#FFFFFF",
                    padding: [0, 0, 0, 12],
                  },
                },
              },
            },
          },
          // 背景条形图
          {
            name: "背景",
            type: "bar",
            barWidth: 4,
            barGap: "-100%",
            data: ["19640", "19640", "19640", "19640"],
            itemStyle: {
              normal: {
                color: "#999",
              },
            },
          },
        ],
      },
      timing: null,
    };
  },
  created() {
    // https://www.isqqw.com/viewer?id=16902
  },
  mounted() {
    this.getQuantityDataData();
    this.timeFn();
  },
  beforeDestroy() {
    clearInterval(this.timing);
  },
  methods: {
    timeFn() {
      this.timing = setInterval(() => {
        this.getQuantityDataData();
      }, 5000);
    },
    getQuantityDataData() {
      // 健身房信息
      const myThis = this;
      service.get('/gym/quantityData')
          .then((response) => {
            if(response.code != 200) {
              console.log(response.message);
              return false;
            }
            const data = response.data;
            const allData = data.one_class_num+data.two_class_num+data.city_num+data.village_num;
            const optionData = [data.one_class_num,data.two_class_num,data.city_num,data.village_num]
            myThis.totalNum = data.num;
            myThis.options.yAxis[1].data = optionData;
            myThis.options.series[0].data = optionData;
            myThis.options.series[1].data = [allData,allData,allData,allData];
          })
          .catch((response) => {
            console.log(response)
          })
    },
  },
};
</script>

              <style lang="scss" scoped>
@import "../../../assets/scss/index.scss";
.LeftDisplayContainer {
}
</style>
