<template>
  <div class="CenterStarContainer">
    <div class="use-status-con d-flex flex-column">
      <p class="distribute-center-text" style="padding-bottom: 22px;">星级排行(个)</p>
      <div class="data-distribute d-flex h-100 w-100">
        <div class="distribute-left-content" style="width: 40%">
          <div class="distribute-img"></div>
          <div v-for="(item,index) in starData" :key="index"
               :class="`distribute-process-item line-${index} distribute-center-text`">
            {{item.value}}
          </div>
        </div>
        <div class="distribute-right-content d-flex flex-column">
          <div v-for="(item,index) in starData" :key="index" :class="`distribute-data-item line-${index}`">
            {{item.name}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

                <script>
//   import ContentTop from "@/components/contentTop";
import service from "@/utils/request";
export default {
  components: {
    //   ContentTop,
  },
  data() {
    return {
      starData: [],
      timing: null,
    };
  },
  mounted() {
    this.getstarRankingData();
    this.timeFn();
  },
  beforeDestroy() {
    clearInterval(this.timing);
  },
  methods: {
    timeFn() {
      this.timing = setInterval(() => {
        this.getstarRankingData();
      }, 5000);
    },
    getstarRankingData() {
      // 星级排行
      const myThis = this;
      service.get('/gym/starRanking')
          .then((response) => {
            if(response.code != 200) {
              console.log(response.message);
              return false;
            }
            const data = response.data;
            myThis.starData = data.map((item) => {
              return {
                name: item.name,
                value: item.num
              }
            })
          })
          .catch((response) => {
            console.log(response)
          })
    },
  },
};
</script>

                <style lang="scss" scoped>
@import "../../../assets/scss/index.scss";
.CenterStarContainer {
  margin-top: 20%;
  .distribute-center-text {
    width: 270px;
    text-align: center;
    font-size: 24px;
    font-family: DIN, DIN-Bold;
    font-weight: 700;
  }
  .data-distribute {
    justify-content: center;
    .line-0 {
      top: 43px;
    }
    .line-1 {
      top: 136px;
    }
    .line-2 {
      top: 210px;
    }
    .line-3 {
      bottom: 88px;
    }
    .line-4 {
      bottom: 24px;
    }
    .distribute-left-content {
      position: relative;
      .distribute-img {
        display: inline-block;
        height: 382px;
        width: 298px;
        background: url("~@/assets/sportFacilities/distribute.png");
        background-size: cover;
      }
      .distribute-process-item {
        position: absolute;
        left: 0;
      }
    }
    .distribute-right-content {
      font-size: 16px;
      font-weight: 700;
      width: 60px;
      position: relative;
      .distribute-data-item {
        position: absolute;
      }
    }
  }
}
</style>
