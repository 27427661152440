<template>
  <div class="LeftBuildContainer">
    <ContentTop
      title="赛事活动排名"
      :topOptionsProps="cityActiveOptionProps"
      :type="2"
    />
    <div class="rank-list" style="margin-top: 21px">
      <rank-list view-type="other" :listHeight="323" :list="areaData" />
    </div>
  </div>
</template>

<script>
import ContentTop from '@/components/contentTop'
import rankList from '@/components/rankList'
export default {
  components: {
    ContentTop,
    rankList,
  },
  data() {
    return {
      cityActiveOptionProps: {
        selects: [
          {
            name: 'cityTopSelect',
            options: [{ value: 1, label: '红榜' }],
          },
        ],
      },
      tableTitle: [
        {
          title: '排名',
          width: '80',
        },
        {
          title: '场地名称',
          val: 'first',
          width: '100',
        },
        {
          title: '人均场地面积 (㎡)',
          val: 'second',
          width: '140',
        },
        {
          title: '较去年',
          val: 'three',
          width: '120',
        },
      ],
      areaData: [
        {
          first: '温州奥体中心',
          second: '70',
          three: '60',
        },
        {
          first: '温州体育中心',
          second: '68',
          three: '56',
        },
        {
          first: '温州龙舟中心',
          second: '62',
          three: '57',
        },
        {
          first: '苍南体育中心',
          second: '60',
          three: '50',
        },
        {
          first: '瑞安体育中心',
          second: '58',
          three: '49',
        },
        {
          first: '泰顺体育中心',
          second: '56',
          three: '46',
        },
        {
          first: '乐清体育中心',
          second: '50',
          three: '40',
        },
        {
          first: '瓯海体育中心',
          second: '41',
          three: '38',
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/index.scss';
.LeftBuildContainer {
}
</style>
