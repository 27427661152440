<template>
	<div class="container">
		<div class="leftView">
			<div class="top">
				<div style="width:40%;">
					<img src="@/assets/grassrootsSports/totalPeople.png">
					<div>
						<p>基层体育委员(人)</p>
						<light-plate :lightNum="[5,3,6,5,3]" />
					</div>
				</div>
				<div style="width:30%;">
					<img src="@/assets/grassrootsSports/siteNum.png">
					<div>
						<p>工作总站(个)</p>
						<light-plate :lightNum="[2,6,3,5]" />
					</div>
				</div>
				<div style="width:30%;">
					<img src="@/assets/grassrootsSports/littleSiteNum.png">
					<div>
						<p>工作分站(个)</p>
						<light-plate :lightNum="[1,2,5,6]" />
					</div>
				</div>
			</div>
			<div class="center">
				<div class="centerLeft">
					<div class="siteScale">
						<Echart style="transform: rotateX(55deg);" :options="rightOptions" height="450px" width="550px">
						</Echart>
						<div class="siteNum">
							<p>{{siteCoverProbability}} <span>%</span></p>
							<p>站点覆盖率</p>
						</div>
					</div>
					<div class="ageScale">
						<Echart :options="options" height="160px" width="160px"></Echart>
						<p class="firstP">
							<span class="squareDrop" style="background-color: #2a9dff;"></span>
							<span>5-15岁 </span>
							<span>21%</span>
						</p>
						<p class="secondP">
							<span class="squareDrop" style="background-color: #F7A600;"></span>
							<span>16-30岁 </span>
							<span>40%</span>
						</p>
						<p class="threeP">
							<span>21%</span>
							<span>50岁以上</span>
							<span class="squareDrop" style="background-color: #00FFFF;"></span>
						</p>
						<p class="fourP">
							<span>21%</span>
							<span>31-55岁</span>
							<span class="squareDrop" style="background-color: #FF3BA4;"></span>
						</p>
					</div>
					<div class="cardList">
						<div class="cardItem">
							<div class="imgBox">
								<img src="@/assets/grassrootsSports/man.png" alt="">
							</div>
							<div class="fontBox">
								<p>男性</p>
								<p style="color:#00FFFF;">76.8%</p>
							</div>
						</div>
						<div class="cardItem">
							<div class="imgBox">
								<img src="@/assets/grassrootsSports/wuman.png" alt="">
							</div>
							<div class="fontBox">
								<p>女性</p>
								<p style="color:#FF3BA4;">32.2%</p>
							</div>
						</div>
					</div>
				</div>
				<div class="centerRight">
					<Echart :options="pieOptions" height="100%" width="100%">
					</Echart>
				</div>
			</div>

		</div>
		<div class="rightView">
			<ContentTop title="排行榜" :topOptionsProps="cityActiveOptionProps" :type="2" />
			<rank-list style="margin-top:10px;" :listWidth="400" :listHeight="900" :tableTitle="tableTitle" />
		</div>
	</div>
</template>

<script>
	import drawMixin from "@/utils/drawMixin";
	import rankList from "@/components/rankList";
	import lightPlate from "@/components/lightPlate";
	import {
		formatTime
	} from "@/utils/index.js";
	import Echart from '@/common/echart'
	import lineChart from '@/components/lineChart'
	import echarts from 'echarts'
	import progressBar from '@/components/progressBar'
	import ContentTop from "@/components/contentTop";
	export default {
		mixins: [drawMixin],
		components: {
			rankList,
			lightPlate,
			Echart,
			lineChart,
			progressBar,
			ContentTop
		},
		data() {
			return {
				timing: null,
				loading: true,
				dateDay: null,
				dateYear: null,
				dateWeek: null,
				weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
				decorationColor: ["#568aea", "#000000"],
				options: {},
				rightOptions: {},
				pieOptions: {},
				siteCoverProbability: 85,
				cityActiveOptionProps: {
					selects: [{
							name: "cityTopSelect",
							options: [{
								value: 1,
								label: "红榜"
							}],
						},
						{
							name: "rank2",
							options: [{
								value: 2,
								label: "鹿城区"
							}],
						}
					],
				},
				tableTitle: [{
					title: '排名',
					width: '80'
				}, {
					title: '姓名',
					val: 'first',
					width: '120'
				}, {
					title: '履职次数',
					val: 'second',
					width: '100'
				}, {
					title: '服务人次',
					val: 'three',
					width: '100'
				}],
				//contHeight:0,
			};
		},
		created() {
			//this.contHeight = document.body.clientHeight - 100;
			//console.log(this.contHeight);

		},

		mounted() {
			this.timeFn();
			this.setCenterPie();
			this.cancelLoading();
			this.setRightPie(this.siteCoverProbability);
			this.setBottomLine();
			//this.test();
		},
		beforeDestroy() {
			clearInterval(this.timing);
		},
		methods: {
			test() {
				this.http({
					url: "productionBI/device-list",
					method: "post",
					data: {
						name: ""
					}
				}).then(res => {
					console.log(res);
				}).finally(() => {
					this.loading = false;
				})
			},
			//中间饼图
			setCenterPie() {
				this.options = {
					series: [{
						name: 'chart',
						type: 'pie',
						roseType: false,
						radius: [62, 75],
						hoverAnimation: false,
						center: ['49%', '49%'],
						label: {
							normal: {
								show: true,
								position: 'center',
								color: '#fff',
								formatter: [
									'{a|各年龄}',
									'{a|占比}'
								].join('\n'),
								rich: {
									a: {
										color: '#fff',
										fontSize: 22,
										textAlign: 'center'
									},
								}
							},
							emphasis: { //中间文字显示
								show: true,
							}
						},
						itemStyle: {
							normal: {
								borderColor: 'rgba(0,0,0, 0.5)',
								borderWidth: 4,
								label: {
									show: false //隐藏文字
								},
								labelLine: {
									show: false //隐藏指示线
								}
							},
						},
						data: [{
								value: 21,
								name: '50岁以上',
								itemStyle: {
									color: '#00FFFF'
								},
							},
							{
								value: 40,
								name: '16-30岁',
								itemStyle: {
									color: '#F7A600'
								},
							},
							{
								value: 21,
								name: '5-15岁',
								itemStyle: {
									color: '#2A9DFF'
								},
							},
							{
								value: 21,
								name: '31-55岁',
								itemStyle: {
									color: '#FF3BA4'
								},
							},

						],
						animationEasing: 'cubicInOut',
						animationDuration: 2600
					}]
				}
			},
			//站点覆盖率饼图
			setRightPie(num) {
				num = Math.floor(num / 5);
				console.log(num);
				let opData = [];
				for (let i = 1; i < 20; i++) {
					let item = {
						value: 1,
						name: i <= num ? '覆盖率' : '未覆盖',
						itemStyle: {
							color: i <= num ? '#2A9DFF' : '#fff'
						},
					}
					opData.push(item)
				};
				this.rightOptions = {
					series: [{
						name: 'chart',
						type: 'pie',
						roseType: false,
						radius: ['55%', '70%'],
						hoverAnimation: false,
						center: ['50%', '50%'],
						label: {
							emphasis: { //中间文字显示
								show: false,
							}
						},
						itemStyle: {
							normal: {
								borderColor: 'rgba(10,22,56, 0.3)',
								borderWidth: 10,
								label: {
									show: false //隐藏文字
								},
								labelLine: {
									show: false //隐藏指示线
								}
							},
						},
						data: opData,
						animationEasing: 'cubicInOut',
						animationDuration: 2600
					}, {
						name: '外边框1',
						type: 'pie',
						tooltip: {
							show: false,
						},
						clockWise: false,
						hoverAnimation: false,
						center: ['50%', '50%'],
						radius: ['80%', '80%'],
						label: {
							normal: {
								show: false
							}
						},
						data: [{
							value: 1,
							name: '',
							itemStyle: {
								normal: {
									borderWidth: 1,
									borderColor: 'rgba(13,99,140)' //边框颜色
								}
							}
						}, {
							value: 1,
							name: '',
							itemStyle: {
								normal: {
									borderWidth: 2,
									borderColor: 'rgba(18,139,183)' //边框颜色
								}
							}
						}]
					}, {
						name: '外边框2',
						type: 'pie',
						tooltip: {
							show: false,
						},
						clockWise: false,
						hoverAnimation: false,
						center: ['50%', '50%'],
						radius: ['90%', '90%'],
						label: {
							normal: {
								show: false
							}
						},
						data: [{
								value: 1,
								name: '',
								itemStyle: {
									normal: {
										borderWidth: 3,
										borderColor: '#0b6083', //边框颜色
										shadowBlur: 20, // 图形阴影的模糊大小。该属性配合 shadowColor,shadowOffsetX, shadowOffsetY 一起设置图形的阴影效果
										shadowOffsetX: 0, // 阴影水平方向上的偏移距离
										shadowOffsetY: 0,
										shadowColor: '#0b6083'
									}
								}
							}, {
								value: 1,
								name: '',
								itemStyle: {
									normal: {
										borderWidth: 5,
										borderColor: '#18D2FE', //边框颜色
										shadowBlur: 20, // 图形阴影的模糊大小。该属性配合 shadowColor,shadowOffsetX, shadowOffsetY 一起设置图形的阴影效果
										shadowOffsetX: 0, // 阴影水平方向上的偏移距离
										shadowOffsetY: 0,
										shadowColor: '#18D2FE'
									}
								}
							},
							{
								value: 1,
								name: '',
								itemStyle: {
									normal: {
										borderWidth: 3,
										borderColor: '#0b6083', //边框颜色
										shadowBlur: 20, // 图形阴影的模糊大小。该属性配合 shadowColor,shadowOffsetX, shadowOffsetY 一起设置图形的阴影效果
										shadowOffsetX: 0, // 阴影水平方向上的偏移距离
										shadowOffsetY: 0,
										shadowColor: '#0b6083'
									}
								}
							}
						]
					}, {
						name: '内边框',
						type: 'pie',
						tooltip: {
							show: false,
						},
						clockWise: false,
						hoverAnimation: false,
						center: ['50%', '50%'],
						radius: ['35%', '35%'],
						label: {
							normal: {
								show: false
							}
						},
						data: [{
								value: 1,
								name: '',
								itemStyle: {
									normal: {
										borderWidth: 1,
										borderColor: 'rgba(13,99,140)' //边框颜色
									}
								}
							}, {
								value: 1,
								name: '',
								itemStyle: {
									normal: {
										borderWidth: 2,
										borderColor: 'rgba(24,114,250)' //边框颜色
									}
								}
							},
							{
								value: 1,
								name: '',
								itemStyle: {
									normal: {
										borderWidth: 1,
										borderColor: 'rgba(13,99,140)' //边框颜色
									}
								}
							}
						]
					}]
				}
			},
			//柱状图
			setBottomLine() {
				this.pieOptions = {
					legend: {
						center: 'auto',
						top: '3%',
						data: [{
								name: '街道覆盖率',
								icon: 'rect',
							},
							{
								name: '村社覆盖率',
								icon: 'rect',
							}
						],
					},
					xAxis: [{
						type: 'value',
						axisLabel: {
							formatter: (value) => {
								if (value < 0) {
									return -value
								}
								return value;
							}
						}
					}],
					yAxis: [{
						type: 'category',
						data: ['鹿城区', '乐青市', '瓯海区', '龙湾区', '永嘉县', '瑞安市', '文成县', '平阳县', '泰顺县', '洞头区',
							'苍南县', '龙港市'
						],
						axisPointer: {
							type: 'shadow'
						},
						axisLabel: {
							textStyle: {
								fontSize: '16',
								margin: '20'
							},

						}

					}],
					series: [{
							name: '街道覆盖率',
							type: 'bar',
							stack: 'Total',
							legendHoverLink: false,
							showBackground: '#020927',
							barWidth: '10px',
							label: {
								show: true,
								position:['0%','-120%'],
								//offset: [100, 0],
								color: '#fff',
								formatter: (param) => {
									if (param.value < 0) {
										return -param.value +'%'
									}
									return param.value +'%';
								}
							},
							emphasis: {
								focus: 'series'
							},
							
							itemStyle: {
								normal: {
									color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
											offset: 0,
											color: 'rgba(24,144,255,0.35)'
										},
										{
											offset: 1,
											color: 'rgba(24,144,255,1)'
										}
									], false)
								}
							
							},
							data: [
								-70, -47, -66, -50, -62, -75, -63, -66, -54, -70, -42,
								-38,
								
							]
						},
						{
							name: '村社覆盖率',
							type: 'bar',
							stack: 'Total',
							hoverAnimation: false,
							showBackground: '#020927',
							barWidth: '10px',
							
							itemStyle: {
								normal: {
									color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
											offset: 0,
											color: 'rgba(8,253,254,0)'
										},
										{
											offset: 1,
											color: 'rgba(8,253,254,1)'
										}
									], false)
								}
							
							},
							lineStyle: {
								width: 4,
							},
							label: {
								show: true,
								position: ['100%','-120%'],
								color: '#fff',
								formatter: (param) => {
									if (param.value < 0) {
										return -param.value +'%'
									}
									return param.value +'%';
								}
							},
							symbolSize: 0,
							data: [
								80, 62, 74, 58, 43, 70, 52, 72, 76, 36, 54, 62,
							]
						}
					]
				};
			},
			timeFn() {
				this.timing = setInterval(() => {
					this.dateDay = formatTime(new Date(), "HH: mm: ss");
					this.dateYear = formatTime(new Date(), "yyyy-MM-dd");
					this.dateWeek = this.weekday[new Date().getDay()];
				}, 1000);
			},
			cancelLoading() {
				setTimeout(() => {
					this.loading = false;
				}, 500);
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import "../../assets/scss/index.scss";

	.container {
		width: 100%;
		height: 100%;
		display: flex;
		padding: 10px;

		>div {
			height: 100%;
		}

		.leftView {
			flex: 1;
			padding: 10px 10px 10px 0;
			height: 960px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			//background: url('~@/assets/boxBorder.jpg') no-repeat;
			background-size: 100% 100%;
			margin-right: 10px;

			.top {
				display: flex;
				width: 100%;
				height: 140px;

				>div {
					padding: 0 1%;
					display: flex;

					img {
						width: 73px;
						height: 61px;
						margin-right: 10px;
					}

					>div {
						flex: 1;

						p {
							margin: 0 0 20px 0;
							padding: 0 0 10px 0;
							line-height: 36px;
							font-size: 26px;
							color: #fff;
							border-bottom: 1px solid;
							border-image: linear-gradient(90deg, rgba(68, 137, 253, 0.80), rgba(68, 137, 253, 0.00) 100%) 2 2;
							position: relative;
						}

						p::after {
							content: '';
							position: absolute;
							bottom: 0;
							left: 0;
							width: 65px;
							height: 4px;
							background-color: #4489FD;
						}
					}

				}
			}

			.center {
				width: 100%;
				flex: 1;
				display: flex;
				align-items: center;
				justify-content: space-between;

				.centerLeft {
					width: 50%;
					height: 100%;
					display: flex;
					flex-direction: column;
					align-items: center;
				}

				.centerRight {
					width: 50%;
					height: 100%;
				}

				.cardList {
					width: 100%;
					padding: 0 10%;
					display: flex;
					align-items: center;
					justify-content: space-between;
					color: #fff;

					.cardItem:first-child {
						.imgBox {
							background: linear-gradient(180deg, #034364, #02527b 100%);
							border-image: linear-gradient(180deg, #18d2fe, #008ed8 100%) 1 1;
							box-shadow: 0px 6px 11px 0px rgba(120, 184, 243, 0.25) inset;
						}
					}

					.cardItem:last-child {
						.imgBox {
							background: linear-gradient(180deg, #69043a, #980855 100%);
							border-image: linear-gradient(180deg, #fb68b7, #ff3ba4 100%) 1 1;
							box-shadow: 0px 6px 11px 0px rgba(255, 255, 255, 0.25) inset;
						}

					}

					.cardItem {
						width: 258px;
						height: 66px;
						border-radius: 40px;
						display: flex;
						align-items: center;
						background: rgba(255, 59, 164, 0.23);
						border: 1px solid rgba(255, 255, 255, 0.23);

						.imgBox {
							width: 56px;
							height: 54px;
							border-radius: 50%;
							margin-left: 5px;
							display: flex;
							justify-content: center;
							align-items: center;

							img {
								width: 21px;
								height: 38px;
							}
						}

						.fontBox {
							flex: 1;
							height: 66px;
							margin-left: 10px;
							display: flex;
							align-items: center;
							justify-content: space-around;

							p:first-child {
								font-size: 22px;
							}

							p:last-child {
								font-size: 32px;
								font-weight: bold;
							}
						}

					}
				}

				.ageScale {
					width: 585px;
					height: 317px;
					position: relative;
					display: flex;
					justify-content: center;
					align-items: center;
					background: url('~@/assets/grassrootsSports/center.png');
					background-size: cover;

					p {
						width: 160px;
						height: 40px;
						position: absolute;
						display: flex;
						align-items: center;
						justify-content: space-between;
						color: #fff;

						.squareDrop {
							width: 9px;
							height: 9px;
							border-radius: 50%;
						}

					}

					.firstP {
						top: 100px;
					}

					.secondP {
						bottom: 100px;
					}

					.firstP,
					.secondP {
						right: 0;

						span:nth-child(2) {
							font-size: 16px;
						}

						span:last-child {
							font-size: 22px;
						}
					}

					.threeP {
						top: 100px;
					}

					.fourP {
						bottom: 100px;
					}

					.threeP,
					.fourP {
						left: 0;

						span:first-child {
							font-size: 22px;
						}

						span:nth-child(2) {
							font-size: 16px;
						}
					}
				}

				.siteScale {
					width: 100%;
					height: 380px;
					display: flex;
					justify-content: center;
					position: relative;

					.siteNum {
						width: 550px;
						position: absolute;
						top: 100px;
						left: calc((100% - 550px)/2);
						color: #fff;

						p {
							text-align: center;
						}

						p:first-child {
							font-size: 110px;

							span {
								font-size: 50px;
								margin-left: -30px;
							}
						}

						p:last-child {
							font-size: 30px;
							margin-top: 40px;
						}
					}
				}
			}
		}

		.rightView {
			width: 400px;

		}
	}
</style>
