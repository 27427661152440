<template>
	<div>
		<Echart :options="options" id="bottomLeftChart" :height="contHeight" :width="contWidth"></Echart>
	</div>
</template>

<script>
	import Echart from '@/common/echart'
	export default {
		data() {
			return {
				options: {},
			};
		},
		components: {
			Echart,
		},
		props: {
			cdata: {
				type: Object,
				default: () => ({})
			},
			contHeight:{
				type:String,
				default:'400px'
			},
			contWidth:{
				type:String,
				default:'800px'
			},
		},
		watch: {
			cdata: {
				handler(newData) {
					this.options = newData;
				},
				immediate: true,
				deep: true
			},
		},
	}
</script>
