<template>
  <div class="RightCenterContainer">
    <ContentTop title="百姓健身房信息" :type="2" />
    <div style="margin-top: 20px">
      <AccumulateTip
        iconName="gym_icon"
        :width="60"
        :labelWidth="100"
        label="百姓健身房器械数量"
        :count="totalNum"
        :countMargin="16"
        unit="个"
      />
    </div>
    <div class="rank-list" style="margin-top: 20px">
      <rank-list :listHeight="250" :tableTitle="tableTitle" :list="areaData" />
    </div>
  </div>
</template>

<script>
import ContentTop from '@/components/contentTop'
import rankList from '@/components/rankList'
import AccumulateTip from '@/components/accumulateTip'
import service from "@/utils/request";
export default {
  components: {
    ContentTop,
    rankList,
    AccumulateTip,
  },
  data() {
    return {
      totalNum: 0,
      tableTitle: [
        {
          title: '排名',
          width: '100',
        },
        {
          title: '器械名称',
          val: 'first',
          width: '150',
        },
        {
          title: '器械数量',
          val: 'second',
          width: '150',
        },
      ],
      areaData: [
        {
          first: '哑铃',
          second: '61500',
        },
        {
          first: '杠铃',
          second: '23150',
        },
        {
          first: '仰卧板',
          second: '13698',
        },
        {
          first: '有氧设备',
          second: '11230',
        },
        {
          first: '跑步机',
          second: '10000',
        },
        {
          first: '健身车',
          second: '7869',
        },
        {
          first: '椭圆机',
          second: '6657',
        },
        {
          first: '划船机',
          second: '5649',
        },
        {
          first: '腿举器',
          second: '4211',
        },
      ],
      timing: null,
    }
  },
  mounted() {
    this.getTrafficRankingData();
    this.timeFn();
  },
  beforeDestroy() {
    clearInterval(this.timing);
  },
  methods: {
    timeFn() {
      this.timing = setInterval(() => {
        this.getTrafficRankingData();
      }, 5000);
    },
    getTrafficRankingData(code) {
      // 健身房信息（右下
      const myThis = this;
      service.get('/gym/deviceData')
          .then((response) => {
            if(response.code != 200) {
              console.log(response.message);
              return false;
            }
            const data = response.data;
            myThis.totalNum = data.total;
            myThis.areaData = data.list.map((item) => {
              return {
                first: item.name,
                second: item.device_num,
              }
            });
          })
          .catch((response) => {
            console.log(response)
          })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/index.scss';
.RightCenterContainer {
}
</style>
