import { render, staticRenderFns } from "./leftLeague.vue?vue&type=template&id=57f45601&scoped=true&"
import script from "./leftLeague.vue?vue&type=script&lang=js&"
export * from "./leftLeague.vue?vue&type=script&lang=js&"
import style0 from "./leftLeague.vue?vue&type=style&index=0&id=57f45601&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57f45601",
  null
  
)

export default component.exports