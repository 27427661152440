<template>
  <div class="LeftAreaContainer">
    <ContentTop
      title="人均体育场地面积排行"
      :topOptionsProps="cityActiveOptionProps"
      :type="2"
    />
    <div class="rank-list" style="margin-top: 21px">
      <rank-list :listHeight="474" :tableTitle="tableTitle" :list="areaData" />
    </div>
  </div>
</template>

<script>
import ContentTop from '@/components/contentTop'
import rankList from '@/components/rankList'
export default {
  components: {
    ContentTop,
    rankList,
  },
  data() {
    return {
      // 市级协会赛事活动排名 top下拉
      cityActiveOptionProps: {
        selects: [
          {
            name: 'cityTopSelect',
            options: [{ value: 1, label: '红榜' }],
          },
        ],
      },
      tableTitle: [
        {
          title: '排名',
          width: '80',
        },
        {
          title: '场地名称',
          val: 'first',
          width: '100',
        },
        {
          title: '人均场地面积 (㎡)',
          val: 'second',
          width: '140',
        },
        {
          title: '较去年',
          val: 'three',
          width: '120',
        },
      ],
      areaData: [
        {
          first: '足球场',
          second: '60',
          three: '50',
        },
        {
          first: '篮球场',
          second: '48',
          three: '40',
        },
        {
          first: '排球场',
          second: '40',
          three: '38',
        },
        {
          first: '羽毛球场',
          second: '30',
          three: '36',
        },
        {
          first: '田径场',
          second: '29',
          three: '35',
        },
        {
          first: '乒乓球场',
          second: '26',
          three: '30',
        },
        {
          first: '台球场',
          second: '25',
          three: '29',
        },
        {
          first: '保龄球场',
          second: '24',
          three: '28',
        },
        {
          first: '棒球场',
          second: '23',
          three: '26',
        },
        {
          first: '网球场',
          second: '20',
          three: '20',
        },
        {
          first: '游泳馆',
          second: '18',
          three: '19',
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/index.scss';
.LeftAreaContainer {
}
</style>
