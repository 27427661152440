<template>
  <div class="leagueBox" :style="`border-color:${color};`">
    <div class="d-flex leagueItemWrapper">
      <span>{{ dataSoure?.label }}：</span><span>{{ dataSoure?.count }}</span
      >个
    </div>
    <!-- <div class="d-flex leagueItemWrapper">
      <span>{{ dataSoure?.subLabel }}：</span
      ><span>{{ dataSoure?.subCount }}</span
      >人
    </div> -->
  </div>
</template>
    
    <script>
export default {
  props: {
    width: { type: Number, default: 127 },
    color: { type: String, default: "#ED73E8" },
    label: { type: String, default: "" },
    count: { type: Number },
    dataSoure: { type: Object },
  },
  methods: {},
};
</script>
    
    <style lang="scss" scoped>
.leagueBox {
  border: 1px solid;
  border-radius: 6px;
  padding: 6px 0;
  padding-left: 10px;
  width: 122px;
  font-size: 12px;
  font-weight: 700;
  .leagueItemWrapper {
    display: flex;
    // justify-content: center;
    align-items: center;
  }
}
</style>