<template>
  <div id="leftGym">
    <div class="leftGymContainer">
      <ContentTop title="百姓健身房使用情况" :type="1" />
      <div class="pos-relative">
        <AccumulateTip
          iconName="person_icon"
          :paddingTop="18"
          :width="87"
          :height="51"
          label="健身房使用总次数"
          :count="totalNumber"
          unit="人"
        />
        <div>
          <Sliders
            @sliderChange="handleChange"
            height="190px"
            :options="useData"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import echarts from 'echarts'
import Echart from '@/common/echart'
import ContentTop from '@/components/contentTop'
import AccumulateTip from '@/components/accumulateTip'
import LeagueItem from '@/components/leagueItem'
import Sliders from '@/components/Sliders'
import service from "@/utils/request"

export default {
  components: {
    Echart,
    ContentTop,
    AccumulateTip,
    LeagueItem,
    Sliders,
  },
  data() {
    return {
      timing: null,
      totalNumber: 0,
      colorRight: 'red',
      rankRadioProps: {
        name: 'rankRadio',
        options: [
          { value: 'week', label: '等级' },
          { value: 'ji', label: '星级' },
        ],
      },
      useData: [
        { value: 90, label: '鹿城区', rightText: '40%' },
        { value: 86, label: '瓯海区', rightText: '40%' },
        { value: 76, label: '龙湾区', rightText: '40%' },
        { value: 70, label: '洞头区', rightText: '40%' },
        { value: 60, label: '永嘉县', rightText: '40%' },
        { value: 50, label: '乐清市', rightText: '40%' },
        { value: 40, label: '文成县', rightText: '40%' },
      ],
    }
  },
  mounted() {
    this.getData();
    this.timeFn();
  },
  beforeDestroy() {
    clearInterval(this.timing);
  },
  methods: {
    timeFn() {
      this.timing = setInterval(() => {
        this.getData();
      }, 5000);
    },
    getData() {
      const myThis = this;
      service.get('/synthesis/gymData')
          .then((response) => {
            if(response.code != 200) {
              console.log(response.message);
              return false;
            }
            const data = response.data;
            myThis.totalNumber = data.total;
            myThis.useData = data.list.map((item) => {
              return { value: item.num, label: item.name, rightText: item.num+'人' }
            })
          })
          .catch((response) => {
            console.log(response)
          })
    },
    handleChange(index, value) {
      console.log(index, value)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/index.scss';
#leftGym {
  margin-top: 18px;
  .leftGymContainer {
    // height: 318px;
    width: 100%;
    .slider-wrapper {
      // overflow-y: hidden !important;
    }
    .leagueCenter {
      flex: 1;
      //   background: red;
    }
    .leagueItem {
      .itemBox {
        margin-top: 18px;
        &:first-child {
          margin-top: 5px;
        }
      }
    }
  }
}
</style>
